import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    OnInit,
    Signal,
    ViewChild,
    WritableSignal,
    computed,
    inject,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { TranslocoModule } from '@jsverse/transloco';
import { EbCustomDrawerComponent } from 'libs/ui-custom-drawer/src/lib/drawer/custom-drawer.component';
import { IconComponent } from '../../../../../../../libs/ui-icons/src/lib/icon/icon.component';
import { Category } from '../../../rest-api/models/category.model';
import { City } from '../../../rest-api/models/province.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { CategoryStoreService } from '../../../stores/category-store.service';
import { CityStoreService } from '../../../stores/city-store.service';

@Component({
    selector: 'eb-category-mega-menu-drawer',
    templateUrl: './category-mega-menu-drawer.component.html',
    styleUrls: ['./category-mega-menu-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        UiDrawerModule,
        UiButtonsModule,
        UiPipeModule,
        TranslocoModule,
        IconComponent,
        EbCustomDrawerComponent,
    ],
})
export class EbCategoryMegaMenuDrawerComponent implements OnInit {
    private readonly _categoryStoreService = inject(CategoryStoreService);
    private readonly _cityStoreService = inject(CityStoreService);
    private readonly _destroyRef = inject(DestroyRef);
    protected readonly i18nService = inject(InternationalizationService);

    isVisible: Signal<boolean> = signal(false);
    categoryTree: ITreeOption<Category>[] = [];
    activeCategory: WritableSignal<Category | null> = signal(null);
    activeSubcategory: WritableSignal<Category | null> = signal(null);

    categories: WritableSignal<Category[]> = signal([]);
    cities: WritableSignal<City[]> = signal([]);

    readonly visibleCitesLength = 24;
    readonly containerElement = viewChild.required<ElementRef<HTMLDivElement>>('container');
    ebCustomDrawerComponent = viewChild.required(EbCustomDrawerComponent);

    constructor() {
        this._cityStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((cities) => {
                this.cities.set(cities);
            });
    }

    public ngOnInit(): void {
        this._categoryStoreService
            .load()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((categories) => {
                this.categories.set(categories);
                this.categoryTree = TreeSelectMapper.map(
                    categories,
                    (c) => c.categoryName,
                    (c) => c.subcategory,
                );
            });

        this.isVisible = computed(() => this.ebCustomDrawerComponent().isVisible());
    }

    public toggleShow(): void {
        this.ebCustomDrawerComponent().toggleShow();
        this._resetCategories();
    }

    protected onTouchendMainCategory(event: TouchEvent, category: Category): void {
        event.preventDefault();
    }

    protected onTouchmoveCategory(event: TouchEvent, category: Category): void {
        event.stopPropagation();
    }

    protected onTouchendCategory(event: TouchEvent, category: Category): void {
        if (category.parentId) {
            this.activeSubcategory.set(category);
        } else {
            this.activeCategory.set(category);
            event.preventDefault();
            (this.containerElement().nativeElement.parentNode as HTMLDivElement).scrollTop = 0;
        }
    }

    private _resetCategories(): void {
        this.activeCategory.set(null);
        this.activeSubcategory.set(null);
    }
}
