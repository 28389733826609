import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, Signal, signal, viewChild } from '@angular/core';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { EbCustomDrawerComponent } from 'libs/ui-custom-drawer/src/lib/drawer/custom-drawer.component';
import { EbQuickSearchInputGroupComponent } from '../quick-search-input-group/quick-search-input-group.component';

@Component({
    selector: 'eb-search-drawer',
    templateUrl: './search-drawer.component.html',
    styleUrls: ['./search-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UiDrawerModule, EbQuickSearchInputGroupComponent, EbCustomDrawerComponent],
})
export class EbSearchDrawerComponent implements OnInit {
    isVisible: Signal<boolean> = signal(false);

    ebQuickSearchInputGroupComponent = viewChild.required(EbQuickSearchInputGroupComponent);
    ebCustomDrawerComponent = viewChild.required(EbCustomDrawerComponent);

    constructor() {
        effect(() => {
            this.ebQuickSearchInputGroupComponent().formSubmitted.subscribe(() => this.closeDrawer());
        });
    }

    public ngOnInit(): void {
        this.isVisible = computed(() => this.ebCustomDrawerComponent().isVisible());
    }

    protected closeDrawer(): void {
        this.ebCustomDrawerComponent().closeWithNoAnimation();
    }
}
