import { Component, inject, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { TopbarStatementType } from './topbar-statement.model';

@Component({
    selector: 'eb-topbar-statement',
    templateUrl: './topbar-statement.component.html',
    styleUrl: './topbar-statement.component.less',
    standalone: true,
    imports: [CommonModule, TranslocoModule],
})
export class EbTopbarStatementComponent {
    readonly blackweekLpUrl = 'https://www.ebilet.pl/lp/black-friday';
    readonly christmasLpUrl = 'https://www.ebilet.pl/lp/prezent-na-swieta';

    statementType: InputSignal<TopbarStatementType> = input<TopbarStatementType>('google-price');
}
