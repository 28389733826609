import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, InputSignal, model, ModelSignal } from '@angular/core';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { IBasket } from '../../../rest-api/models/basket.model';

@Component({
    selector: 'eb-basket-drawer',
    templateUrl: './basket-drawer.component.html',
    styleUrl: './basket-drawer.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UiDrawerModule],
})
export class BasketDrawerComponent {
    basket: InputSignal<IBasket> = input.required<IBasket>();
    isVisible: ModelSignal<boolean> = model.required<boolean>();
}
