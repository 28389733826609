import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() {
        // Hack that should ensure that GlobalErrorHandler works in zoneless.
        // rozwiązanie wzięte z:
        // https://github.com/angular/angular/issues/56240
        // https://stackblitz.com/edit/stackblitz-starters-uhzn52?file=src%2Fmain.ts
        if (typeof window !== 'undefined') {
            window.addEventListener('error', (event) => {
                this.handleError(event.error);
                event.preventDefault();
            });
            window.addEventListener('unhandledrejection', (e) => {
                this.handleError(e.reason);
                e.preventDefault();
            });
        } else {
            if (process.listenerCount('unhandledRejection') === 0) {
                process.on('unhandledRejection', (reason, promise) => {
                    this.handleError(reason);
                });
            }

            if (process.listenerCount('uncaughtException') === 0) {
                process.on('uncaughtException', (err) => {
                    this.handleError(err);
                });
            }
        }
    }

    handleError(error: any): void {
        console.error('Error from global error handler', error);
    }
}
