<div class="eb-footer" [style]="branding()?.footerColor ? 'background-color: ' + branding()?.footerColor : ''">
  <div class="eb-footer__content" [ngClass]="{ 'eb-footer__content--no-border': branding()?.footerColor }">
    @if (!branding()?.footerColor) {
      <div class="eb-divider eb-size--huge"></div>
    }

    @if (branding()) {
      <!-- branding view -->
      <div class="eb-footer__copyright-and-social">
        <span class="eb-footer__copyright-and-powered">
          @if (branding()?.footerImage) {
            <a (click)="redirect()">
              <img [src]="branding()?.footerImage | media" alt="footer" class="eb-footer__branding-img" />
            </a>
          }
        </span>
        <div>
          @for (footerLink of branding()?.footerLinks; track $index) {
            <a [href]="footerLink.url" [rel]="(footerLink.url | isExternalUrl) ? 'noopener' : ''">
              {{ footerLink.anchor }}
            </a>
          }
        </div>
      </div>
    } @else {
      <!-- default view -->
      @if (footer()) {
        <div class="eb-footer-section-container mt-6 md:mt-0">
          @for (section of footer()?.items; track $index) {
            <div class="eb-footer-section">
              <div class="eb-footer-section__name">{{ section.name }}</div>
              <div class="eb-footer-section__item-container">
                @for (link of section.items; track $index) {
                  <div class="eb-footer-section__item">
                    @if (link.isUrlExternal) {
                      <a [href]="link.url | ebSafeUrl" [rel]="(link.url | isExternalUrl) ? 'nofollow noopener' : 'nofollow'" [ngClass]="{ 'font-bold': link.isBolded }">
                        {{ link.name }}
                      </a>
                    } @else {
                      <a [routerLink]="generateInternalLink(link.url)" [ngClass]="{ 'font-bold': link.isBolded }">{{ link.name }}</a>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }

      @if (footer()) {
        <div class="eb-divider eb-size--huge"></div>
      }

      <div class="eb-footer__copyright-and-social">
        <span class="eb-footer__copyright-and-powered">
          <span class="flex align-items-center">Copyright {{ year() }} by eBilet</span>
        </span>
        <div>
          <a [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'newsletter']">
            <eb-icon fontSize="16px" key="envelope" />
            Newsletter
          </a>

          <a [href]="socialUrls.facebook" rel="nofollow noopener">
            <eb-icon fontSize="16px" key="brand-facebook" />
            Facebook
          </a>

          <a [href]="socialUrls.instagram" rel="nofollow noopener">
            <eb-icon fontSize="16px" key="brand-instagram" />
            Instagram
          </a>

          <a [href]="socialUrls.youtube" rel="nofollow noopener">
            <eb-icon fontSize="16px" key="brand-youtube" />
            Youtube
          </a>

          <a [href]="socialUrls.tiktok" rel="nofollow noopener">
            <eb-icon fontSize="16px" key="brand-tiktok" />
            TikTok
          </a>

          <a [href]="socialUrls.linkedin" rel="nofollow noopener">
            <eb-icon fontSize="16px" key="brand-linkedin" />
            Linkedin
          </a>
        </div>
      </div>
    }
  </div>
  @defer {
    <eb-scroll-button />
  }
</div>
