import { ChangeDetectionStrategy, Component, output, OutputEmitterRef, TemplateRef, viewChild } from '@angular/core';
import { EbDropdownPanel } from '../dropdown-panel.interface';

@Component({
    selector: 'eb-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDropdownComponent implements EbDropdownPanel {
    readonly templateRef = viewChild.required<TemplateRef<void>>(TemplateRef);
    readonly closed: OutputEmitterRef<void> = output<void>();

    protected onDropdownClick(): void {
        this.closed.emit();
    }
}
