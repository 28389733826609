import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeRestService } from '../rest-api/home-rest.service';
import { FooterDto, FooterLinkDto, FooterPageType, FooterSectionDto } from '../rest-api/models/footer.model';
import { Footer, FooterLink, FooterSection } from '../shared/components/footer/footer.component';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class FooterStoreService extends BaseStoreService<null, Footer> {
    private readonly _homeRestService = inject(HomeRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<Footer> {
        return () => this._homeRestService.getFooter().pipe(map((res) => this._mapFooterDto(res)));
    }

    public load(): Observable<Footer> {
        return super.load(null);
    }

    public get(): Observable<Footer> {
        this.load();
        return super.get();
    }

    private _mapFooterDto(footerDto: FooterDto): Footer {
        return {
            items: footerDto.items.map(
                (section: FooterSectionDto) =>
                    ({
                        name: section.name,
                        items: section.items.map(
                            (link: FooterLinkDto) =>
                                ({
                                    name: link.name,
                                    url: this._generateUrlForItem(link),
                                    isUrlExternal: this._checkLinkExternality(link),
                                    isBolded: this._checkIfUrlShouldBeBolded(link.pageType),
                                }) as FooterLink,
                        ),
                    }) as FooterSection,
            ),
        } as Footer;
    }

    private _generateUrlForItem(item: FooterLinkDto): string {
        switch (item.pageType) {
            case FooterPageType.CONTENT:
                return item.slug;
            case FooterPageType.FAQ_TYPE_BUYER:
                return '/centrum-pomocy/';
            case FooterPageType.FAQ_TYPE_SELLER:
                return 'https://www.ebilet.pl/now/wspolpraca/#kontakt';
            case FooterPageType.FAQ_BUYER_CATEGORY:
                return `/centrum-pomocy/${item.slug}`;
            case FooterPageType.FOOTER_ITEM_LINK:
                return this._addProtocolIfNecessary(item.slug);
            default:
                return item.slug;
        }
    }

    private _checkLinkExternality(item: FooterLinkDto): boolean {
        return item.pageType === FooterPageType.FOOTER_ITEM_LINK || item.pageType === FooterPageType.FAQ_TYPE_SELLER;
    }

    private _addProtocolIfNecessary(link: string): string {
        return link.substring(0, 10).includes('://') || link.includes('javascript:') ? link : 'http://' + link;
    }

    private _checkIfUrlShouldBeBolded(pageType: number): boolean {
        const pageTypeToBeBolded = [2, 3];
        return pageTypeToBeBolded.includes(pageType);
    }
}
