@if (showChristmasStatement() && !iframe()) {
  <eb-topbar-statement [statementType]="'christmas'"></eb-topbar-statement>
}
@if (showBlackweekStatement() && !iframe()) {
  <eb-topbar-statement [statementType]="'blackweek'"></eb-topbar-statement>
}
@if (showGooglePriceStatement()) {
  <eb-topbar-statement [statementType]="'google-price'"></eb-topbar-statement>
}
@if (!iframe()) {
  <eb-topbar *transloco="let t" #topbarEl [backgroundColor]="branding()?.headerColor">
    <a (click)="redirect($event)" class="mr-5" [href]="homeUrl">
      <eb-logo [imageUrl]="branding()?.headerImage"></eb-logo>
    </a>
    @if (!branding()) {
      <!-- default view-->
      <button eb-button class="eb-lg-visible mr-2 category-button" (click)="toggleMegaMenu()">
        <eb-icon [className]="'icon--lg-root-relative'" [key]="menuVisible() ? 'close' : 'grid'" />
        {{ t('shared.portal-master-page.categories') }}
      </button>

      @if (isQuickSearchVisible() && !isMobile()) {
        <button
          eb-button
          attr.aria-label="{{ t('shared.portal-master-page.search') }}"
          ebType="secondary"
          [ebGhost]="true"
          class="mr-2"
          [ebOverlay]="overlayPanel"
          [ebOverlayGlobal]="true"
        >
          <eb-icon [key]="'search'" [className]="'icon--lg-root-relative'" />
        </button>
      }

      @if (isSimpleSearchVisible() && !isMobile()) {
        <div class="mr-2">
          <eb-search-select [debounceTime]="300" />
        </div>
      }

      <a eb-button [ebGhost]="true" class="ml-auto topbar-link white-space-nowrap justify-content-center" [href]="blogUrl">
        <eb-icon [className]="'icon--lg-root-relative'" [key]="'mdn-player'" />
        <span class="eb-lg-visible ml-2"> eBilet NOW </span>
      </a>

      <a eb-button [ebGhost]="true" class="topbar-link eb-xl-visible justify-content-center" [href]="collaborationUrl">
        <eb-icon [key]="'collaboration'" [className]="'icon--lg-root-relative'" />
        <span class="eb-lg-visible ml-2">
          {{ t('shared.portal-master-page.collaboration') }}
        </span>
      </a>

      <a
        eb-button
        [ebGhost]="true"
        class="topbar-link justify-content-center"
        [routerLink]="[internationalizationService.isEnglishActive() ? '/en' : '/', 'newsletter']"
      >
        <eb-icon [key]="'envelope'" [className]="'icon--lg-root-relative'" />
        <span class="eb-lg-visible ml-2"> Newsletter </span>
      </a>

      @if (!branding()) {
        <ng-container *ngTemplateOutlet="languageDropdownTrigger" />
      }

      <button
        eb-button
        attr.aria-label="{{ t('shared.portal-master-page.yourAccount') }}"
        [ebGhost]="true"
        class="eb-lg-visible mr-2"
        (click)="goToLoginPage()"
      >
        <eb-icon [key]="'user'" [className]="'icon--lg-root-relative'" />
      </button>
      <!-- @if(isBasketButtonVisible) {
        <div class="basket--button-wrapper">
          <button
            #dropdownTrigger="ebDropdownTriggerForDirective"
            eb-button
            [active]="dropdownTrigger.isDropdownOpen"
            [ebDropdownTriggerFor]="basketDropdown"
            [ebDropdownWidth]="basketDropdownWidth"
            attr.aria-label="{{ t('shared.portal-master-page.basket.shoppingBasket') }}"
            ebType="secondary"
            [ebGhost]="true"
            class="w-full"
            (click)="handleBasketClick()"
          >
          <svg-icon class="icon--lg-root-relative" key="shopping-cart-full"/>
          <div class="eb-text-divider eb-size--lg eb-lg-visible"></div>
          <span class="basket-timer"><eb-minutes-timer [endDate]="basketExpirationDate()!" (timerEnded)="handleTimerEnd()"/></span>
        </button>

        </div>
      } -->
    } @else {
      <ng-container *ngTemplateOutlet="languageDropdownTrigger"></ng-container>
    }
  </eb-topbar>
}

<div #page class="eb-page">
  <div #megaMenuOverlay class="mega-menu-overlay eb-fade-out" [ngClass]="{ 'eb-fade-in': menuVisible(), 'eb-fade-out': !menuVisible() }">
    @if (!isMobile()) {
      <div class="mega-menu-overlay-content">
        <eb-category-mega-menu [menuVisible]="menuVisible()"></eb-category-mega-menu>
      </div>
    }
  </div>

  <eb-overlay-panel #overlayPanel [overlayStyle]="'eb-title-event-calendar-item-overlay-panel'">
    <ng-template let-data>
      <div class="search-overlay-panel">
        <div class="search-overlay-panel-content">
          <button eb-button ebSize="large" (click)="overlayPanel.close()">
            <eb-icon [key]="'chevron-left'" />
          </button>
          <eb-quick-search-input-group [autoFocus]="true" [showPrimarySearchButton]="true"></eb-quick-search-input-group>
        </div>
      </div>
    </ng-template>
  </eb-overlay-panel>

  <router-outlet />
</div>
@defer (when iframe()) {
  @if (iframe()?.footerVisible) {
    <eb-footer-iframe [dark]="iframe()?.type === iframeTypeEnum.DARK" />
  }
}
<eb-footer *ebIsBrowser [branding]="branding()" />

@if (!iframe()) {
  <eb-bottom-nav-bar class="eb-lg-hidden" (otherDrawerOpened)="closeBasketDrawer()" />
}

<ng-template #languageDropdownTrigger>
  <ng-container *transloco="let t">
    <button
      #dropdownTrigger="ebDropdownTriggerForDirective"
      eb-button
      [active]="dropdownTrigger.isDropdownOpen()"
      attr.aria-label="{{ t('shared.portal-master-page.selectLanguage') }}"
      [ebDropdownTriggerFor]="languageChoiceDropdown"
      [ebDropdownWidth]="languageDropdownWidth"
      [ebGhost]="true"
    >
      <span class="ml-2">{{ internationalizationService.activeLanguage() | uppercase }}</span>
      <eb-icon [key]="dropdownTrigger.isDropdownOpen() ? 'chevron-up' : 'chevron-down'" [size]="'smaller'" [className]="'icon-smaller'" />
    </button>
  </ng-container>
</ng-template>

<eb-dropdown #languageChoiceDropdown>
  @for (language of internationalizationService.availableLanguages; track language.id) {
    <button
      eb-button
      [ebType]="internationalizationService.activeLanguage() === language.id ? 'secondary' : 'default'"
      [ebGhost]="internationalizationService.activeLanguage() !== language.id ? true : false"
      class="eb-dropdown-item"
      (click)="setLanguage(language.id)"
    >
      {{ language.id | uppercase }} - {{ language.label }}
    </button>
  }
</eb-dropdown>

<eb-dropdown #basketDropdown>
  <ng-template [ngTemplateOutlet]="basketContent" />
  <ng-template [ngTemplateOutlet]="basketContent" />
</eb-dropdown>

@if (basket()) {
  <eb-basket-drawer [(isVisible)]="isBasketDrawerVisible" [basket]="basket()!">
    <ng-template [ngTemplateOutlet]="basketContent" />
  </eb-basket-drawer>
}

<ng-template #basketContent>
  @if (basket()) {
    <ng-container *transloco="let t">
      <span class="basket--events-count pb-4"> {{ t('shared.portal-master-page.basket.eventsCount') + basket()!.eventsCount }}</span>
      <div class="basket--events custom-scrollbar">
        @for (basketItem of basket()?.activeBasketEventsData; track $index) {
          <p class="basket--event-title h6 ellipsis pb-1">{{ basketItem.title }}</p>
          <p class="basket--event-info eb-size--tiny ellipsis font-normal">
            {{ basketItem.date }} / {{ basketItem.place.city }} / {{ basketItem.place.name }}
          </p>
          <p class="basket--event-info eb-size--tiny ellipsis font-normal">
            {{ basketItem.date }} / {{ basketItem.place.city }} / {{ basketItem.place.name }}
          </p>
          <div class="eb-divider"></div>
        }
      </div>
      <button
        class="basket--move-button"
        eb-button
        ebType="primary"
        (click)="redirectToShop(basket()!.activeBasketEventsData[basket()!.activeBasketEventsData.length - 1].eventId)"
      >
        <eb-icon class="icon--lg-root-relative" key="shopping-cart" />
        {{ t('shared.portal-master-page.basket.moveToBasket') }}
      </button>
    </ng-container>
  }
</ng-template>
