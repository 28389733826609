import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { UiPipeModule } from '@e-bilet/ui-pipe';

@Component({
    selector: 'eb-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UiPipeModule],
})
export class EbLogoComponent {
    imageUrl: InputSignal<string | undefined> = input<string | undefined>();
}
