import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    DestroyRef,
    Directive,
    ElementRef,
    HostListener,
    InputSignal,
    ViewContainerRef,
    WritableSignal,
    inject,
    input,
    signal,
} from '@angular/core';
import { outputToObservable, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subscription, merge } from 'rxjs';
import { EbDropdownPanel } from './dropdown-panel.interface';

@Directive({
    selector: '[ebDropdownTriggerFor]',
    exportAs: 'ebDropdownTriggerForDirective',
})
export class EbDropdownTriggerForDirective {
    private readonly _overlay = inject(Overlay);
    private readonly _elementRef = inject(ElementRef);
    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _destoryRef = inject(DestroyRef);

    isDropdownOpen: WritableSignal<boolean> = signal(false);
    private _overlayRef: WritableSignal<OverlayRef | null> = signal(null);
    private _dropdownClosingActionsSub: Subscription = Subscription.EMPTY;

    @HostListener('click') public toggleDropdown(): void {
        this.isDropdownOpen() ? this._destroyDropdown() : this._openDropdown();
    }

    public dropdownPanel: InputSignal<EbDropdownPanel> = input.required({
        alias: 'ebDropdownTriggerFor',
    });

    public dropdownWidth: InputSignal<string> = input.required({
        alias: 'ebDropdownWidth',
    });

    private _openDropdown(): void {
        this.isDropdownOpen.set(true);
        this._overlayRef.set(
            this._overlay.create({
                hasBackdrop: true,
                backdropClass: 'cdk-overlay-transparent-backdrop',
                scrollStrategy: this._overlay.scrollStrategies.close(),
                width: this.dropdownWidth(),
                positionStrategy: this._overlay
                    .position()
                    .flexibleConnectedTo(this._elementRef)
                    .withPositions([
                        {
                            originX: 'end',
                            originY: 'bottom',
                            overlayX: 'end',
                            overlayY: 'top',
                            offsetY: 8,
                        },
                    ]),
            }),
        );

        if (this.dropdownPanel().templateRef()) {
            const templatePortal = new TemplatePortal(this.dropdownPanel().templateRef(), this._viewContainerRef);
            this._overlayRef()?.attach(templatePortal);
        }

        this._dropdownClosingActionsSub = this._dropdownClosingActions()
            .pipe(takeUntilDestroyed(this._destoryRef))
            .subscribe(() => this._destroyDropdown());
    }

    private _dropdownClosingActions(): Observable<any> {
        const backdropClick$ = this._overlayRef()?.backdropClick();
        const detachment$ = this._overlayRef()?.detachments();
        const dropdownClosed$ = outputToObservable(this.dropdownPanel()?.closed);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return merge(backdropClick$!, detachment$!, dropdownClosed$);
    }

    private _destroyDropdown(): void {
        if (!this._overlayRef || !this.isDropdownOpen) {
            return;
        }

        this._dropdownClosingActionsSub?.unsubscribe();
        this.isDropdownOpen.set(false);
        this._overlayRef()?.detach();
    }
}
