<eb-custom-drawer #drawer>
  <div #container class="main-container eb-category-mega-menu py-2" *transloco="let t">
    @if(activeCategory()) {
      <div class="eb-category-mega-menu__actionbar">
        <button eb-button (click)="activeCategory.set(null); activeSubcategory.set(null)">
          <eb-icon key="chevron-left" />
          <span>{{ activeCategory()?.categoryName }}</span>
        </button>
      </div>
    }
    <div [ngClass]="{ hidden: activeCategory() }">
      <a [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'wydarzenia']" (click)="drawer.close()" class="category-tree__option">
        <div>{{ t('shared.category-mega-menu-drawer.allEvents') }}</div>
      </a>
      @for(category of categories(); track category.id) {
        <div
          class="category-tree__option"
          (touchend)="onTouchendMainCategory($event, category)"
          (click)="activeCategory.set(category); activeSubcategory.set(null)"
        >
          <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: category, hasIcon: true }" />
        </div>
      }
    </div>
    <div>
      @for(category of categories(); track category.id) {
        <div
          class="category-tree__subcategory"
          [ngClass]="{ 'category-tree__subcategory--active': category === activeCategory() }"
        >
          <a
            [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug]"
            class="category-tree__option"
            (click)="activeCategory.set(category); activeSubcategory.set(null); drawer.close()"
          >
            <div>
              <span>{{ t('shared.category-mega-menu-drawer.allEvents') }}</span>
            </div>
          </a>
          @for(subcategory of category.subcategory; track subcategory.id) {
            <a
              [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, subcategory.slug]"
              class="category-tree__option"
              (click)="activeSubcategory.set(subcategory); drawer.close()"
            >
              <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: subcategory, hasIcon: false }" />
            </a>
          }
        </div>
      }
      <div class="category-tree__events-in-city">
        @if(cities().length && !activeCategory() && !activeSubcategory()) {
          <div>
            <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
            @if(cities().length > 0 && !activeCategory() && !activeSubcategory()) {
              <div class="category-tree__cities">
                @for(city of cities() | slice: 0 : visibleCitesLength; track city.slug) {
                  <span>
                    @if(city) {
                      <a
                        eb-button
                        ebType="tag"
                        [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'miasto', city.slug]"
                        (click)="drawer.close()">
                        {{ city.name }}
                    </a>
                    }
                  </span>
                }
              </div>
            }
          </div>
        }

        @for(category of categories(); track category.id) {
          <div>
            <div [ngClass]="activeCategory() === category && !activeSubcategory() ? '' : 'hidden'">
              @if(category.mostPopularCities?.length) {
                <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
              }
              <div class="category-tree__cities">
                @for(mostPopularCity of category.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
                  <span>
                    @if(mostPopularCity?.name) {
                      <a
                        eb-button
                        ebType="tag"
                        [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]"
                        (click)="drawer.close()">
                        {{ mostPopularCity.name }}
                    </a>
                    }
                  </span>
                }
              </div>
            </div>
            @for(subcategory of category.subcategory; track subcategory.id) {
              <div [ngClass]="activeSubcategory() === subcategory ? '' : 'hidden'">
                @if(subcategory.mostPopularCities?.length) {
                  <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
                }
                <div class="category-tree__cities">
                  @for(mostPopularCity of subcategory.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
                    <span>
                      @if(mostPopularCity?.name) {
                        <a
                          eb-button
                          ebType="tag"
                          [routerLink]="[
                            i18nService.isEnglishActive() ? '/en' : '/',
                            category.slug,
                            subcategory.slug,
                            'miasto',
                            mostPopularCity.slug
                          ]"
                          (click)="drawer.close()">
                          {{ mostPopularCity.name }}
                      </a>
                      }
                    </span>
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</eb-custom-drawer>
<div class="hidden">
  @for(city of cities() | slice: 0 : visibleCitesLength; track $index) {
    <span>
      @if(city) {
        <a [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'miasto', city.slug]">{{ city.name }}</a>
      }
    </span>
  }
  @for(category of categories(); track category.id) {
    <div>
      @for(mostPopularCity of category.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
        <span>
          @if(mostPopularCity?.name) {
            <a
              [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]"
              (click)="drawer.close()">
              {{ mostPopularCity.name }}
          </a>
          }
        </span>
      }
      @for(subcategory of category.subcategory; track subcategory.id) {
        <div>
          @for(mostPopularCity of subcategory.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
            <span>
              @if(mostPopularCity?.name) {
                <a
                  [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, subcategory.slug, 'miasto', mostPopularCity.slug]"
                  (click)="drawer.close()">
                  {{ mostPopularCity.name }}
              </a>
              }
            </span>
          }
        </div>
      }
    </div>
  }
</div>

<ng-template #categoryTmpl let-category let-routerLink="routerLink" let-hasIcon="hasIcon">
  <div (touchstart)="onTouchmoveCategory($event, category)" (touchend)="onTouchendCategory($event, category)">
    <eb-icon [key]="category?.icon | ebCategoryIcon" />
    <span>
      {{ category?.categoryName }}
    </span>
    @if(hasIcon) {
      <eb-icon key="chevron-right" class="ml-auto" />
    }
  </div>
</ng-template>
