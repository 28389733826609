@import '_variables.less';

.eb-bottom-nav-bar {
    &--active {
        background-color: @Purple-900-o08 !important;
    }
}

.eb-bottom-nav-bar__actions {
    background-color: @Yellow-o90;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;

    backdrop-filter: blur(8px);

    @supports (backdrop-filter: blur(8px)) {
        backdrop-filter: blur(8px);
    }

    @supports (not (backdrop-filter: blur(8px))) {
        background-color: @Yellow;
        border-top: 1px solid @border-color;
    }

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: @bottom-nav-bar-height;
    grid-auto-flow: column;
    z-index: 1001;

    .eb-btn.eb-btn-ghost:not(:disabled):hover {
        background-color: @Purple-900-o08;
        border: unset;
    }
}

.eb-bottom-nav-bar__actions > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    > svg-icon {
        font-size: 1.5rem;
        width: 1em;
        height: 1em;
    }
}
