import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MemoryCache } from 'memory-cache-node';
import { Observable, map, tap } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CACHE_DURATION } from './config/rest.model';

const CACHE = new MemoryCache<string, object>(1, 10);

@Injectable({
    providedIn: 'root',
})
export class RedirectRestService {
    private readonly _httpClient = inject(HttpClient);

    private _portalApi = `${environment.portalApi}`;

    public getRedirectUrl(oldUrl: string): Observable<string> {
        return this._getUrlsDictionary().pipe(
            map((data: any) => {
                const url = decodeURIComponent(oldUrl);
                const newUrl = data[url];
                return newUrl;
            }),
        );
        // Bez cache
        // return this._httpClient.get<string>(`${this._portalApi}/Redirects`, {params: {oldUrl}});
    }

    private _getUrlsDictionary(): Observable<any> {
        if (CACHE.hasItem('data')) {
            return new Observable((observer) => {
                observer.next(CACHE.retrieveItemValue('data'));
                observer.complete();
            });
        }

        const path = `${environment.cacheBlobUrl}/redirects.json`;

        return this._httpClient.get<any>(path).pipe(
            retry({ count: 3, delay: 1000 }),
            tap((response) => {
                try {
                    CACHE.storeExpiringItem('data', response, CACHE_DURATION);
                } catch (error) {
                    console.log('getUrlsDictionary Error', error);
                }
            }),
            map((response) => CACHE.retrieveItemValue('data')),
        );
    }
}
