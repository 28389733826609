<div class="category-tree" *transloco="let t">
  <div>
    <a
      [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'wydarzenia']"
      eb-button
      (mouseenter)="activeCategory.set(null); activeSubcategory.set(null)"
    >
      {{ t('shared.category-mega-menu.allEvents') }}
    </a>
    @for (category of categories(); track category.id) {
      <a
        [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug]"
        eb-button
        (mouseenter)="activeCategory.set(category); activeSubcategory.set(null)"
      >
        <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: category, hasIcon: true }" />
      </a>
    }
  </div>
  <div>
    @for (category of categories(); track category.id) {
      <div class="category-tree__subcategory" [ngClass]="{ 'category-tree__subcategory--active': category === activeCategory() }">
        <a [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug]" eb-button (mouseenter)="activeSubcategory.set(null)">
          <span>{{ 'shared.category-mega-menu.allEvents' | transloco }}</span>
        </a>
        @for (subcategory of category.subcategory; track subcategory.id) {
          <a
            [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, subcategory.slug]"
            eb-button
            (mouseenter)="activeSubcategory.set(subcategory)"
          >
            <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: subcategory, hasIcon: false }" />
          </a>
        }
      </div>
    }
  </div>
  <div>
    @if (onTimeEvents().length > 0 && !activeCategory()) {
      <div>
        <p class="h5">{{ t('shared.category-mega-menu.onTime') }}</p>
        <div class="category-tree__titles mt-3">
          @for (titleOnTime of onTimeEvents() | slice: 0 : 6; track titleOnTime.id; let i = $index; let count = $count) {
            <a
              (click)="onClick($event, titleOnTime)"
              [href]="
                titleOnTime.linkTo ??
                ([i18nService.isEnglishActive() ? 'en' : '', titleOnTime.category, titleOnTime.subcategory, titleOnTime.slug]
                  | ebArrayToUrlString)
              "
              ebIntersection
              [ebOnlyFirst]="true"
              (intersecting)="onTimeEventIntersecting(titleOnTime, i, count)"
            >
              <img [src]="menuInited() ? (titleOnTime.imageLandscape | media) : ''" [alt]="titleOnTime.slug" width="198" height="112" />
            </a>
          }
        </div>
      </div>
    }
    @if (activeCategory()) {
      <div>
        @if ((activeCategory()?.titlesOnTime?.length ?? 0) > 0) {
          <p class="h5">{{ t('shared.category-mega-menu.onTime') }}</p>
        }
        <div class="category-tree__titles mt-3">
          @for (titleOnTime of activeCategory()?.titlesOnTime; track titleOnTime.id; let i = $index; let count = $count) {
            <img
              [src]="menuInited() ? (titleOnTime.imageLandscape | media) : ''"
              [alt]="titleOnTime.slug"
              width="198"
              height="112"
              [routerLink]="[
                i18nService.isEnglishActive() ? '/en' : '/',
                activeCategory()?.slug,
                titleOnTime?.subcategory,
                titleOnTime?.slug
              ]"
              ebIntersection
              [ebOnlyFirst]="true"
              (intersecting)="categoryOnTimeEventIntersecting(titleOnTime, i, count)"
            />
          }
        </div>
      </div>
    }
    @if ((!activeCategory() && !activeSubcategory()) || activeCategory()?.articles?.length || activeSubcategory()?.articles?.length) {
      <div class="eb-lg-visible mt-5">
        <p class="h5 mb-4">{{ t('shared.articles.articlesHeader') }}</p>
        <eb-article-card-container [articles]="articles()" [isMegaMenu]="true" [loading]="loadingCategories()" />
      </div>
    }
    <div class="mt-5">
      @if (cities().length && !activeCategory() && !activeSubcategory()) {
        <div>
          <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>

          <div class="category-tree__cities">
            @for (city of cities() | slice: 0 : visibleCitesLength; track city.slug) {
              <span>
                @if (city) {
                  <a eb-button ebType="tag" [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', 'miasto', city.slug]">
                    {{ city.name }}
                  </a>
                }
              </span>
            }
          </div>
        </div>
      }

      @for (category of categories(); track category.id) {
        <div>
          <div [ngClass]="activeCategory() === category && !activeSubcategory() ? '' : 'hidden'">
            @if (category.mostPopularCities?.length) {
              <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
            }
            <div class="category-tree__cities">
              @for (mostPopularCity of category.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
                <span>
                  @if (mostPopularCity?.name) {
                    <a
                      eb-button
                      ebType="tag"
                      [routerLink]="[i18nService.isEnglishActive() ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]"
                    >
                      {{ mostPopularCity.name }}
                    </a>
                  }
                </span>
              }
            </div>
          </div>
          @for (subcategory of category.subcategory; track subcategory.id) {
            <div [ngClass]="activeSubcategory() === subcategory ? '' : 'hidden'">
              @if (subcategory.mostPopularCities?.length) {
                <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
              }
              <div class="category-tree__cities">
                @for (mostPopularCity of subcategory.mostPopularCities | slice: 0 : visibleCitesLength; track $index) {
                  <span>
                    @if (mostPopularCity?.name) {
                      <a
                        eb-button
                        ebType="tag"
                        [routerLink]="[
                          i18nService.isEnglishActive() ? '/en' : '/',
                          category.slug,
                          subcategory.slug,
                          'miasto',
                          mostPopularCity.slug
                        ]"
                      >
                        {{ mostPopularCity.name }}
                      </a>
                    }
                  </span>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>

<ng-template #categoryTmpl let-category let-routerLink="routerLink" let-hasIcon="hasIcon">
  <eb-icon [key]="category?.icon | ebCategoryIcon" />
  {{ category?.categoryName }}
  @if (hasIcon) {
    <eb-icon [key]="'chevron-right'" />
  }
</ng-template>
