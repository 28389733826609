@import '_variables.less';

.eb-topbar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--page-width);
    margin: 0 auto;
    padding: var(--main-container-padding);

    @media only screen and (min-width: @screen-md-min) {
        &:not(.eb-topbar__content--no-border) {
            border-bottom: 1px solid @border-color;
        }
    }

    .eb-btn.eb-btn-ghost {
        &:not(:disabled):hover {
            background-color: @Purple-900-o08;
        }
    }

    button.eb-btn.category-button {
        border: 1px solid @btn-primary-focus-border-color;
        background-color: #ffffff03;
    }
}

.eb-topbar-menu--active {
    .eb-topbar__content {
        background-color: @White;
    }
}