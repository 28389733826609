import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { REQUEST } from '../../consts/request-response.const';
import { isPlatformServer } from '@angular/common';
export const ErrorInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
    const platformId = inject(PLATFORM_ID);
    const router = inject(Router);
    const res = inject(REQUEST, { optional: true });

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            console.error(req.urlWithParams, error);

            if (isPlatformServer(platformId)) {
                if (error.status >= 500 && error.status < 600) {
                    res?.res?.status(500);
                    router.navigate(['/500']);
                }
            }

            return throwError(() => error);
        }),
    );
};
