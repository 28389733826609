import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    InputSignal,
    ModelSignal,
    OnChanges,
    OnInit,
    SimpleChanges,
    WritableSignal,
    computed,
    inject,
    input,
    model,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { UiArticleCardModule } from '@e-bilet/ui-article-card';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { TranslocoModule } from '@jsverse/transloco';
import { AggregatedGa4EventsService } from 'libs/gtm/src/lib/aggregated-ga4-events.service';
import { tap } from 'rxjs';
import { Ga4ListNames } from '../../../../../../../libs/gtm/src/lib/models/ga4-list-names.enum';
import { IconComponent } from '../../../../../../../libs/ui-icons/src/lib/icon/icon.component';
import { Article } from '../../../rest-api/models/article.interface';
import { Category, ITitleOnTime } from '../../../rest-api/models/category.model';
import { City } from '../../../rest-api/models/province.model';
import { Title } from '../../../rest-api/models/title.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { NavigationService } from '../../../services/navigation.service';
import { CategoryStoreService } from '../../../stores/category-store.service';
import { CityStoreService } from '../../../stores/city-store.service';
import { CreativeContentStoreService } from '../../../stores/creative-content-store.service.ts.service';
import { OnTimeStoreService } from '../../../stores/on-time-store.service';

@Component({
    selector: 'eb-category-mega-menu',
    templateUrl: './category-mega-menu.component.html',
    styleUrls: ['./category-mega-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TranslocoModule,
        UiButtonsModule,
        IconComponent,
        UiPipeModule,
        UiArticleCardModule,
    ],
})
export class EbCategoryMegaMenuComponent implements OnInit, OnChanges {
    private readonly _onTimeStoreService = inject(OnTimeStoreService);
    private readonly _cityStoreService = inject(CityStoreService);
    private readonly _categoryStoreService = inject(CategoryStoreService);
    private readonly _creativeContentStoreService = inject(CreativeContentStoreService);
    private readonly _navigationService = inject(NavigationService);
    public readonly i18nService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);
    public readonly aggregatedGa4EventsService = inject(AggregatedGa4EventsService);
    private _destroyRef = inject(DestroyRef);
    private _viewedOnTimeLists: Set<string> = new Set<string>();

    activeCategory: WritableSignal<Category | null> = signal(null);
    activeSubcategory: WritableSignal<Category | null> = signal(null);
    onTimeEvents: WritableSignal<Title[]> = signal([]);
    cities: WritableSignal<City[]> = signal([]);
    homePageArticles: WritableSignal<Article[]> = signal([]);
    categoryTree: ITreeOption<Category>[] = [];
    ngForIterationsCounter = 0;
    menuInited: WritableSignal<boolean> = signal(false);
    loadingCategories: WritableSignal<boolean> = signal(false);
    readonly visibleCitesLength = 24;
    readonly Ga4ListName = Ga4ListNames;

    articles = computed(
        () =>
            (this.activeSubcategory()?.articles?.length
                ? this.activeSubcategory()?.articles
                : this.activeCategory()?.articles?.length
                  ? this.activeCategory()?.articles
                  : this.homePageArticles()) ?? [],
    );

    menuVisible: InputSignal<boolean> = input.required();
    categories: ModelSignal<Category[]> = model<Category[]>([]);

    readonly containerElement = viewChild.required<ElementRef<HTMLDivElement>>('container');

    constructor() {
        this._onTimeStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((events) => {
                this.onTimeEvents.set(events);
            });

        this._cityStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((cities) => {
                this.cities.set(cities);
            });

        this._creativeContentStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((articles: Article[]) => {
                this.homePageArticles.set(articles.slice(0, 2));
            });
    }

    public ngOnInit(): void {
        this._categoryStoreService
            .load()
            .pipe(
                tap(() => this.loadingCategories.set(true)),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe((categories) => {
                this.categories.set(categories);
                this.categoryTree = TreeSelectMapper.map(
                    categories,
                    (c) => c.categoryName,
                    (c) => c.subcategory,
                );
                this.loadingCategories.set(false);
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.menuVisible()) {
            this.menuInited.set(true);
        }
    }

    protected onTouchendMainCategory(event: TouchEvent, category: Category): void {
        event.preventDefault();
    }

    protected onTouchmoveCategory(event: TouchEvent, category: Category): void {
        event.stopPropagation();
    }

    protected onTouchendCategory(event: TouchEvent, category: Category): void {
        if (category.parentId) {
            this.activeSubcategory.set(category);
        } else {
            this.activeCategory.set(category);
            event.preventDefault();
            (this.containerElement().nativeElement.parentNode as HTMLDivElement).scrollTop = 0;
        }
    }

    protected onClick(e: Event, title: Title): void {
        e.preventDefault();
        this._navigationService.navigateToTitle(title);
    }

    protected onTimeEventIntersecting(titleOnTime: Title, index: number, count: number): void {
        this.ngForIterationsCounter++;
        const categoryName = 'onTimeEvents';
        if (!this._viewedOnTimeLists.has(categoryName)) {
            this.aggregatedGa4EventsService.titleCardIntersecting(
                Ga4ListNames.MegamenuOnTime,
                titleOnTime,
                index,
                Ga4ListNames.MegamenuOnTime,
                true,
            );

            if (this.ngForIterationsCounter === count) {
                this._viewedOnTimeLists.add(categoryName);
                this.ngForIterationsCounter = 0;
            }
        }
    }

    protected categoryOnTimeEventIntersecting(titleOnTime: ITitleOnTime, index: number, count: number): void {
        this.ngForIterationsCounter++;
        if (!this._viewedOnTimeLists.has(titleOnTime.category)) {
            this.aggregatedGa4EventsService.iTitleOnTimeIntersecting(
                Ga4ListNames.MegamenuCategory,
                titleOnTime,
                index,
                Ga4ListNames.MegamenuCategory,
            );

            if (this.ngForIterationsCounter === count) {
                this._viewedOnTimeLists.add(titleOnTime.category);
                this.ngForIterationsCounter = 0;
            }
        }
    }
}
