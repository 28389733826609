import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    HostBinding,
    inject,
    output,
    Signal,
    signal,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { TranslocoModule } from '@jsverse/transloco';
import { IconComponent } from '../../../../../../../libs/ui-icons/src/lib/icon/icon.component';
import { UrlHelper } from '../../../helpers/url.helper';
import { InternationalizationService } from '../../../services/internationalization.service';
import { EbCategoryMegaMenuDrawerComponent } from '../category-mega-menu-drawer/category-mega-menu-drawer.component';
import { EbSearchDrawerComponent } from '../search-drawer/search-drawer.component';

@Component({
    selector: 'eb-bottom-nav-bar',
    templateUrl: './bottom-nav-bar.component.html',
    styleUrls: ['./bottom-nav-bar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        UiButtonsModule,
        TranslocoModule,
        EbCategoryMegaMenuDrawerComponent,
        EbSearchDrawerComponent,
        IconComponent,
    ],
})
export class EbBottomNavBarComponent implements AfterViewInit {
    private readonly _i18nService = inject(InternationalizationService);

    protected isMegaMenuDrawerVisible: Signal<boolean> = signal(false);
    protected isSearchDrawerVisible: Signal<boolean> = signal(false);

    ebCategoryMegaMenuDrawerComponent = viewChild.required(EbCategoryMegaMenuDrawerComponent);
    ebSearchDrawerComponent = viewChild.required(EbSearchDrawerComponent);

    otherDrawerOpened = output<void>();

    @HostBinding('class.eb-bottom-nav-bar') protected get isBottomNavBar(): boolean {
        return true;
    }

    public ngAfterViewInit(): void {
        this.isMegaMenuDrawerVisible = computed(() => this.ebCategoryMegaMenuDrawerComponent().isVisible());
        this.isSearchDrawerVisible = computed(() => this.ebSearchDrawerComponent().isVisible());
    }

    protected showLogin(): void {
        window.location.href = UrlHelper.getLoginUrl(this._i18nService.isEnglishActive());
    }

    protected handleMegaMenuButtonClick(): void {
        this.ebCategoryMegaMenuDrawerComponent().toggleShow();
        this.ebSearchDrawerComponent().ebCustomDrawerComponent().close(true);
        this.otherDrawerOpened.emit();
    }

    protected handleSearchButtonClick(): void {
        this.ebSearchDrawerComponent().ebCustomDrawerComponent().toggleShow();
        const isOpen = this.ebSearchDrawerComponent().ebCustomDrawerComponent().isVisible();

        setTimeout(() => {
            if (!isOpen) {
                this.ebSearchDrawerComponent().ebQuickSearchInputGroupComponent().focus();
                this.ebCategoryMegaMenuDrawerComponent().ebCustomDrawerComponent().close(true);
            }
        });
        this.otherDrawerOpened.emit();
    }
}
