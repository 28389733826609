import { chevronDownIcon } from './shared/icons/chevron-down';
import { chevronLeftIcon } from './shared/icons/chevron-left';
import { chevronRightIcon } from './shared/icons/chevron-right';
import { chevronUpIcon } from './shared/icons/chevron-up';
import { closeIcon } from './shared/icons/close';
import { collaborationIcon } from './shared/icons/collaboration';
import { envelopeIcon } from './shared/icons/envelope';
import { gridIcon } from './shared/icons/grid';
import { mdnPlayerIcon } from './shared/icons/mdn-player';
import { searchIcon } from './shared/icons/search';
import { userIcon } from './shared/icons/user';
import { calendarMonthIcon } from './shared/icons/calendar-month';
import { locationPinIcon } from './shared/icons/location-pin';
import { wwwIcon } from './shared/icons/www';

export const appIcons = [
    chevronLeftIcon,
    chevronRightIcon,
    chevronUpIcon,
    chevronDownIcon,
    closeIcon,
    gridIcon,
    collaborationIcon,
    envelopeIcon,
    mdnPlayerIcon,
    calendarMonthIcon,
    searchIcon,
    userIcon,
    locationPinIcon,
    wwwIcon,
];
