import {
    APP_ID,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    Optional,
    PLATFORM_ID,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    provideRouter,
    UrlSerializer,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withRouterConfig,
} from '@angular/router';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { provideClientHydration, withI18nSupport } from '@angular/platform-browser';
import { AppInsightsWebModule } from '@e-bilet/app-insights-web';
import { SyneriseModule } from '@e-bilet/synerise';
import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { provideTransloco } from '@jsverse/transloco';
import { NZ_DATE_CONFIG, NZ_I18N, pl_PL } from 'ng-zorro-antd/i18n';
import { REQUEST } from '../consts/request-response.const';
import { CHECK_BOT, SEO_BOT } from '../consts/seo-bot';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { CustomUrlSerializer } from './custom-url.serializer';
import { appIcons } from './icons-modules';
import { GlobalErrorHandler } from './infrastructure/global-error-handler';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TranslocoHttpLoader } from './transloco-root.module';

registerLocaleData(localePl);

const scrollConfig: InMemoryScrollingOptions = {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

const routerConfig = withRouterConfig({
    paramsInheritanceStrategy: 'always',
});

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        provideExperimentalZonelessChangeDetection(),
        provideRouter(routes, inMemoryScrollingFeature, routerConfig, withEnabledBlockingInitialNavigation()),
        provideClientHydration(),
        provideHttpClient(withInterceptors([ErrorInterceptor])),
        provideTransloco({
            config: {
                availableLangs: [
                    { id: 'pl', label: 'polski' },
                    { id: 'en', label: 'English' },
                ],
                defaultLang: 'pl',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production,
            },
            loader: TranslocoHttpLoader,
        }),
        importProvidersFrom([SyneriseModule.forRoot(), AppInsightsWebModule.forRoot()]),
        { provide: APP_ID, useValue: 'serverApp' },
        { provide: NZ_I18N, useValue: pl_PL },
        { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: SEO_BOT, useFactory: CHECK_BOT, deps: [PLATFORM_ID, [new Optional(), REQUEST]] },
        provideSvgIconsConfig({
            // TODO: wymyślić lepszy sposób konwertowania kolorów przy nowym sposobie ładowania ikonek
            sizes: {
                smaller: 'smaller',
                xsm: '12px',
                sm: '13px',
                md: '16px',
                lg: '24px',
            },
            defaultSize: 'lg',
        }),
        provideSvgIcons([...appIcons]),
    ],
};
