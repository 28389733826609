<ng-container *transloco="let t">
  @switch (statementType()) {
    @case ('google-price') {
      <span class="eb-size--small google-price-statement-text">{{ t('topbar-statements.google.priceStatement') }}</span>
    }
    @case ('blackweek') {
      <a class="width-100 height-100" [href]="blackweekLpUrl">
        <span class="eb-size--small blackweek-statement-text">{{ t('topbar-statements.blackweek.statement') }}</span>
      </a>
    }
    @case ('christmas') {
      <a class="width-100 height-100" [href]="christmasLpUrl">
        <span class="eb-size--small christmas-statement-text">{{ t('topbar-statements.christmas.statement') }}</span>
      </a>
    }
  }
</ng-container>
