import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppInsightsWebService } from './app-insights-web.service';

@NgModule({
    imports: [CommonModule],
})
export class AppInsightsWebModule {
    static forRoot(): ModuleWithProviders<AppInsightsWebModule> {
        return {
            ngModule: AppInsightsWebModule,
            providers: [AppInsightsWebService],
        };
    }
}
