import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IBasket } from './models/basket.model';

@Injectable({
    providedIn: 'root',
})
export class BasketRestService {
    private readonly _httpClient = inject(HttpClient);

    private readonly _baseUrl = `${environment.portalApi}/Baskets`;

    public getActiveBasketData(sessionId: string): Observable<IBasket> {
        let params = new HttpParams();
        params = params.append('SessionId', sessionId);

        return this._httpClient.get<IBasket>(`${this._baseUrl}/getActiveBasketData`, { params });
    }
}
